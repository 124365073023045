import style from "./Terms.module.scss";

export default function Terms2En() {
  return (
    <div className={style.terms}>
      As the collection and use of personal information is the minimum necessary
      to enable you to use the services provided by exbody. you must agree to
      the items to use the service.
      <br />
      Consent to the collection and use of personal information is not
      mandatory, and you have the right to refuse consent.
      <br /> <br />
      However, if you do not consent to providing your personal information,
      exbody Co., Ltd. will be unable to deliver its services.
      <br />
      <br />
      <h2>
        ■ [Required] Consent to Collection and Use of Personal Information
      </h2>
      <br />
      <table>
        <tr>
          <th>Purpose of Collection and Use</th>
          <th>Items of personal information we collect and use</th>
          <th>Retention period</th>
        </tr>
        <tr>
          <td>Registration and management of the Examinee</td>
          <td>
            [Required] Name, Date of Birth, Phone Number, Year of Employment,
            Duplicate Subscription Verification Information (DI)
            <br />
            <br />
            [Optional] Employee ID, Email, Gender, Workplace, Job Role,
            Department
          </td>
          <td>
            1. Principle: Until the examinee requests deletion.
            <br />
            <br /> 2. However, in cases falling under the following reasons,
            data will be retained until the specified period ends: <br />-
            Records related to consumer complaints or dispute resolution: 3
            years (in accordance with the Act on the Consumer Protection in
            Electronic Commerce, etc.)
          </td>
        </tr>
        <tr>
          <td>Provision of Personalized Services</td>
          <td>
            Examination Session, Initial Examination Date, Examination Results
            (Numerical Data), Examination Results (Images)
          </td>
          <td>
            1. Principle: Until the examinee withdraws.
            <br />
            <br /> 2. However, in cases falling under the following reasons,
            data will be retained until the specified period ends:
            <br />- Records related to consumer complaints or dispute
            resolution: 3 years (in accordance with the Act on the Consumer
            Protection in Electronic Commerce, etc.)
          </td>
        </tr>
      </table>
    </div>
  );
}
