export const setErrorCode = (code: number) => {
  switch (code) {
    case 1:
      return "Mobie_ServerErr_1_Pop_0";
    case 2:
      return "Mobie_ServerErr_1_Pop_0";
    case 101:
      return "Mobile_Common_TypeErr_Text_0";
    case 416:
      return "Mobie_ServerErr_2_Pop_0";
    case 602:
      return "Mobie_ServerErr_3_Pop_0";
    case 603:
      return "Mobile_Join_IdentityVerification_Text_5";
    case 605:
      return "Server_Err_Code_605_0"; // todo
    case 607:
      return "Mobile_UserInfo_NewPassword_Validation_4_Text_0";
    case 610:
      return "Mobile_Join_WithdrawalUser_Text_0";
    case 611:
      return "Mobile_UserInfo_Email_Validation_3_Text_0";
    case 613:
      return "Mobile_UserInfo_Nickname_Validation_3_Text_0";
    case 614:
      return "Mobile_Join_IdentityVerification_Text_5";
    case 615:
      return "Mobie_ServerErr_4_Pop_0";
    case 618:
      return "Server_Err_Code_618_0";
    case 620:
      return "Mobile_UserInfo_AccountLock_Text_0";
    case 625:
      return "Mobile_UserInfo_Nickname_Validation_4_Text_0";
    case 627:
      return "Mobile_Join_IdentityVerification_Err_Text_1_0";
    case 628:
      return "Mobile_Join_IdentityVerification_Err_Text_2_0";
    case 629:
      return "Mobile_Join_IdentityVerification_Err_Text_3_0";
    case 630:
      return "Mobile_Join_IdentityVerification_Err_Text_4_0";
    case 631:
      return "Mobile_Join_IdentityVerification_Err_Text_5_0";
    case 633:
      return "Server_Err_Code_633_0";
    case 634:
      return "Server_Err_Code_634_0";
    case 635:
      return "Server_Err_Code_635_0";
    case 636:
      return "Server_Err_Code_636_0";
    case 1302:
      return "Mobie_ServerErr_5_Pop_0";

    default:
      return code;
  }
};
