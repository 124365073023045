import style from "./Terms.module.scss";

const Terms3Jp = () => {
  return (
    <div className={style.terms}>
      株式会社エックスボディ（以下「会社」という）は、個人情報保護法など関連法令を遵守し、関連法令に基づく個人情報・機密情報処理方針を定めて個人情報保護に最善を尽くしています。
      <br />
      個人情報処理方針は、利用者がいつでも簡単に閲覧できるようにサービス初期画面を通じて公開しており、関連法令、指針、告示及び会社サービス政策の変更によって変わることがあります。その内容をよく読んでから同意した場合にのみ検査を進めてください。
      <br />
      <br />
      <h3>1. 処理する個人情報の項目および目的</h3>
      <br />
      当社は、事業運営、製品およびサービスの提供・改善、利用者への案内、効果的な広告の開発ならびに以下の目的のために、個人情報および敏感情報の項目を収集・処理しています。
      <br />
      <br />
      当社がすべての利用者にサービスを提供するためには、以下の項目の個人情報が必要であり、これは申込者の同意または法令に基づいて収集されます。
      <br />
      <br />
      検査対象者は本同意に対して拒否する権利がありますが、必須項目に対する収集および利用同意を拒否された場合、サービス利用に制限が生じる場合があります。
      <br />
      <br />
      <table>
        <tr>
          <th>収集時期</th>
          <th>収集項目</th>
          <th>収集目的</th>
        </tr>
        <tr>
          <td>検査対象者登録時の個人情報収集内容</td>
          <td>
            [必須項目] 氏名、生年月日、電話番号、入社年、重複加入確認情報（DI）
            <br />
            [任意項目] 社員番号、メールアドレス、性別、事業所、職群、部署
            <br />
            [未成年者の場合、追加必須項目]
            法定代理人の氏名、メールアドレス、携帯電話番号、重複加入確認情報（DI）
          </td>
          <td>
            加入意思の確認、サービスの加入/変更/解約、サービス提供可否の判断（年齢確認等）および重複加入確認、公知事項の通知、検査対象者参加スペースの運営、検査対象者アンケート調査、個人向けサービスの提供、広告情報・提携サービスの案内、商品配送先住所および連絡先の確認。
          </td>
        </tr>
        <tr>
          <td>検査対象者測定時</td>
          <td>[必須項目] 検査回数、初回検査日、検査結果数値、検査結果画像 </td>
          <td>個人向けサービスの提供。</td>
        </tr>
      </table>
      <br />
      <h3>2. 個人情報の処理および保有期間</h3>
      <br />
      1.
      当社は、検査対象者の個人情報を同意日から検査対象者が個人情報の削除を要請するまで保有します。検査対象者が削除を要請した場合、個人情報保護法第39条の6に基づき、速やかに削除または別途分離保管します。ただし、関係法令に基づき保管が必要な情報は例外とします。
      <br />
      <br />
      • 関連法令に基づく個人情報の保有および利用期間
      <br />
      1) 商業帳簿および営業に関する重要書類・伝票: 重要書類10年 /
      伝票5年（商法）
      <br />
      2) 取引に関する帳簿および証憑類:
      5年（国税基本法、法人税法、付加価値税法等）
      <br />
      3) 通信履歴資料（ログ記録、IPアドレス等）: 3ヶ月（通信秘密保護法）
      <br />
      4) 表示・広告に関する記録: 6ヶ月（電子商取引等に関する消費者保護法）
      <br />
      5) 契約または申込撤回に関する記録: 5年（電子商取引等に関する消費者保護法）
      <br />
      6) 代金決済および財・サービスの提供に関する記録:
      5年（電子商取引等に関する消費者保護法）
      <br />
      7) 消費者の苦情・紛争処理に関する記録:
      3年（電子商取引等に関する消費者保護法）
      <br />
      8) 信用情報の収集・処理および利用に関する記録: 3年（信用情報利用・保護法）
      <br />
      9) 本人確認に関する記録: 6ヶ月（情報通信網利用促進および情報保護法）
      <br />
      <br />
      <h3>3. 個人情報の処理委託</h3>
      当社は、サービス向上のために個人情報の処理を他社に委託する場合があります。委託する場合、検査対象者の個人情報が安全に処理されるよう管理・監督し、他の目的で利用されないよう制限します。
      <br />
      1) 個人情報の処理を委託する場合、事前に検査対象者に通知します。
      <br />
      2)
      委託契約を通じ、サービス提供者への個人情報保護指示の遵守、秘密保持、第三者提供禁止、事故時の責任負担を明確に定め、契約内容を書面または電子形式で保管します。
      <br />
      <br />
      <h3>4. 個人情報の破棄手続きおよび方法</h3>
      <br />
      個人情報の保有期間が経過した場合、または収集および利用目的が達成された場合、速やかに破棄します。
      <br />
      <br />
      • 破棄手続き
      <br />
      1) 破棄事由が発生した個人情報は、責任者の承認を得て破棄します。
      <br />
      2)
      入力された情報は目的達成後、別途DBに移され（紙の場合は施錠された保管庫で保管）、関連法令に基づき一定期間保存後、破棄します。
      <br />
      3) 法令で定められた場合を除き、保存目的以外には利用しません。
      <br />
      • 破棄方法
      <br />
      1)
      電子的ファイル形式で保存された個人情報は、復元不可能な技術を用いて破棄します。
      <br />
      2) 紙媒体の個人情報はシュレッダーで破砕します。
      <br />
      <br />
      <h3>5. 個人情報の安全性確保対策</h3>
      <br />
      当社は、個人情報の安全性を確保するため、以下の対策を実施しています。
      <br />
      <br />
      1) 個人情報の暗号化
      パスワードで保護し、重要データは暗号化やファイルロック機能を利用して保護します。
      <br />
      2) ハッキング等への技術的対策
      セキュリティプログラムを導入し、定期的に更新・点検しています。
      <br />
      <br />
      3) 個人情報処理システムへのアクセス制限
      担当者を限定し、専用のパスワードを付与し、定期的に変更しています。
      <br />
      4) 従業員の教育
      従業員に対し、定期的なセキュリティ技術習得および個人情報保護に関する教育を実施しています。
      <br />
      <br />
      5) 非認可者の出入制御
      個人情報を保存するシステムの物理的保管場所を分離し、出入制御手順を運用しています。
      <br />
      <br />
      <h3>6. 個人情報保護責任者</h3>
      <br />
      検査対象者は、当社サービス利用中に発生する個人情報関連の苦情を責任者または担当部署に相談できます。当社は迅速かつ十分な対応を提供します。
      <br />
      <br />
      ・個人情報保護責任者: キム・ミスク
      <br />
      ・ 個人情報担当および閲覧部署: 企業付設研究所 サーバーチーム
      <br />
      ・ 個人情報苦情申請部署: 企画調整室
      <br />
      ・ 苦情申請電話番号: 02-6925-6080
      <br />
      ・ 苦情申請メール: secure@exbody.com
      <br />
      <h3>7. 個人情報侵害に関する申告および相談</h3>
      <br />
      個人情報侵害に関する申告や相談が必要な場合は、以下の機関にお問い合わせください。
      <br />
      <br />
      <table>
        <tr>
          <th>機関</th>
          <th>ホームページ</th>
          <th>電話番号</th>
        </tr>
        <tr>
          <td>個人情報侵害報告センター</td>
          <td>http://privacy.kisa.or.kr/kor/main.jsp</td>
          <td>(局番なし) 118</td>
        </tr>
        <tr>
          <td>個人情報紛争調整委員会</td>
          <td>https://www.kopico.go.kr/ </td>
          <td>1833-6972</td>
        </tr>
        <tr>
          <td>大検察庁サイバー捜査</td>
          <td>http://www.spo.go.kr/spo/index.jsp</td>
          <td>(局番なし) 1301 </td>
        </tr>
        <tr>
          <td>警察庁サイバー安全局</td>
          <td>http://cyberbureau.police.go.kr/index.d</td>
          <td>(局番なし) 182 </td>
        </tr>
      </table>
      <br />
      <br />
      8. 個人情報処理義務
      <br />
      (1)
      本個人情報処理方針は、法令、政府の政策又は会社内部政策の変更等必要に応じて変更されることがあり、内容の追加、削除及び修正がある場合には、改正の少なくとも7日前からXボディサービスホームページを通じてお知らせします。
      <br />
      (2) 本個人情報処理方針は2024年11月15日から適用されます。
      <br />
      <br />
      ・ 公告日: 2024年11月15日
      <br />・ 施行日: 2024年12月17日
    </div>
  );
};

export default Terms3Jp;
