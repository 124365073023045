import { useState, useEffect } from "react";
import style from "./Step3.module.scss";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { restfetcher, QueryKeys, getClient } from "utils/queryClient";
import {
  validateNickname,
  validateEmail,
  validatePassword,
  validatename,
  onlyNumbers,
} from "utils/validate";
import { birthFunc, checkBirth } from "utils/common";
import { setErrorCode } from "utils/error";
import { useGlobalContext } from "context/store";
import { emails, genderDatas } from "datas/common";
import { defaultPassData } from "datas/defaultdata";

import { ReactComponent as SuccessSVG } from "assets/icon/ic_chevron_check.svg";
import { ReactComponent as FailSVG } from "assets/icon/ic_warning_circle_lined.svg";

import TextInput from "components/inputs/textInput/TextInput";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import Select from "components/selects/Select";
import JoinNavigator from "components/nav/JoinNavigator";

const Step3 = () => {
  const queryClient = getClient();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { stroreTerms, passUserInfo, setPassUserInfo } = useGlobalContext();
  const error = {
    email: {
      type: "",
      text: "Mobile_UserInfo_Email_Validation_0_Text_0",
    },
    pw: {
      type: "",
      text: "",
    },
    repw: {
      type: "none",
      text: "Mobile_UserInfo_PasswordReconfirm_0",
    },
    nickname: {
      type: "",
      text: "Mobile_UserInfo_NewNickname_InsertBox_Text_0",
    },
    usrname: {
      type: "none",
      text: "",
    },
    usrbirth: {
      type: "",
      text: "",
    },
  };
  const [selectEmail, setSelectEmail] = useState<DefaultKeyValue>(emails[0]);
  const [changeEmail, setChangeEmail] = useState({
    id: "",
    email: "",
  });
  const [sendEmial, setSendEmail] = useState("");
  const [nickname, setNickname] = useState("");
  const [pw, setPW] = useState({
    pw: "",
    repw: "",
  });
  const [usrinfo, setUsrinfo] = useState({
    usrname: "",
    usrbirth: "",
    usrgender: genderDatas[0],
  });
  const [errors, setErrors] = useState({ ...error });

  const duplicatecheckEmailApi = useQuery({
    queryKey: [QueryKeys.DUPLICATECHECKEMAIL],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/duplicatecheck",
        params: {
          type: "EMAIL",
          data: sendEmial,
        },
      }),
  });

  const duplicatecheckNicknameApi = useQuery({
    queryKey: [QueryKeys.DUPLICATECHECKNICKNAME],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/duplicatecheck",
        params: {
          type: "NICKNAME",
          data: nickname,
        },
      }),
  });

  const joinuserApi = useQuery({
    queryKey: [QueryKeys.JOINUSER],
    queryFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/joinuser",
        params: {
          authidx: Number(passUserInfo?.authidx),
          authkey: passUserInfo?.authkey,
          usrphoneno: passUserInfo?.usrphoneno,
          usrname: usrinfo?.usrname,
          usrbirth: usrinfo.usrbirth,
          usrgender: usrinfo.usrgender.key,
          usremail: sendEmial,
          usrnickname: nickname,
          usrpw: pw.repw,
          terms: stroreTerms,
          countryno: passUserInfo.countryno,
          usrphonecountrycode: passUserInfo.countrycode,
        },
      }),
  });

  useEffect(() => {
    const { isSuccess, data } = duplicatecheckEmailApi;
    if (isSuccess) {
      if (data?.result === "success") {
        const { isexist } = data;
        if (!isexist) {
          setErrors({
            ...errors,
            email: {
              type: "success",
              text: "Mobile_UserInfo_Email_Validation_1_Text_0",
            },
          });
        } else {
          setErrors({
            ...errors,
            email: {
              type: "fail",
              text: "Mobile_UserInfo_Email_Validation_2_Text_0",
            },
          });
        }
      } else {
        setErrors({
          ...errors,
          email: {
            type: "fail",
            text: setErrorCode(data.errcode).toString(),
          },
        });
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.DUPLICATECHECKEMAIL] });
    }
  }, [duplicatecheckEmailApi.isSuccess, duplicatecheckEmailApi.data]);

  useEffect(() => {
    const { isSuccess, data } = duplicatecheckNicknameApi;
    if (isSuccess) {
      if (data?.result === "success") {
        const { isexist } = data;
        if (!isexist) {
          setErrors({
            ...errors,
            nickname: {
              type: "success",
              text: "Mobile_UserInfo_Nickname_Validation_1_Text_0",
            },
          });
        } else {
          setErrors({
            ...errors,
            nickname: {
              type: "fail",
              text: "Mobile_UserInfo_Nickname_Validation_2_Text_0",
            },
          });
        }
      } else {
        setErrors({
          ...errors,
          nickname: {
            type: "fail",
            text: setErrorCode(data.errcode).toString(),
          },
        });
      }
      queryClient.removeQueries({
        queryKey: [QueryKeys.DUPLICATECHECKNICKNAME],
      });
    }
  }, [duplicatecheckNicknameApi.isSuccess, duplicatecheckNicknameApi.data]);

  useEffect(() => {
    const { isSuccess, data } = joinuserApi;
    if (isSuccess) {
      if (data?.result === "success" && data?.usridx) {
        setPassUserInfo(defaultPassData);
        navigate("/join/step4");
      } else {
        alert(t(setErrorCode(data?.errcode)?.toString()));
      }
      queryClient.removeQueries({ queryKey: [QueryKeys.JOINUSER] });
    }
  }, [joinuserApi.isSuccess, joinuserApi.data]);

  const handleDoubleCheckEmail = () => {
    if (changeEmail.id.length > 0 && changeEmail.email.length > 0) {
      let errorEmail = error.email;
      if (validateEmail(sendEmial)) {
        duplicatecheckEmailApi.refetch();
      } else {
        errorEmail = {
          type: "fail",
          text: "Mobile_UserInfo_Email_Validation_2_Text_0",
        };
      }
      setErrors({ ...errors, email: errorEmail });
    }
  };

  const handleDoubleCheckNickname = () => {
    let errorNickname = error.nickname;
    if (validateNickname(nickname)) {
      duplicatecheckNicknameApi.refetch();
    } else {
      return setErrors({
        ...errors,
        nickname: {
          type: "fail",
          text: "Mobile_UserInfo_Nickname_Validation_2_Text_0",
        },
      });
    }
    setErrors({ ...errors, nickname: errorNickname });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (selectEmail?.key === 0) {
        setChangeEmail({ ...changeEmail, email: value });
      } else {
        setChangeEmail({ ...changeEmail, email: selectEmail?.value });
      }
    } else {
      setChangeEmail({ ...changeEmail, id: value });
    }
  };

  const validatePW = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (name === "pw") {
      if (validatePassword(pw.pw)) {
        setErrors({
          ...errors,
          pw: {
            type: "success",
            text: "Mobile_UserInfo_Password_Validation_1_Text_0",
          },
        });
      } else {
        setErrors({
          ...errors,
          pw: {
            type: "fail",
            text: "Mobile_UserInfo_Password_Validation_3_Text_0",
          },
        });
      }
    } else {
      if (validatePassword(pw.repw) && pw.pw === pw.repw) {
        setErrors({
          ...errors,
          repw: {
            type: "success",
            text: "Mobile_UserInfo_Password_Validation_1_Text_0",
          },
        });
      } else {
        setErrors({
          ...errors,
          repw: {
            type: "fail",
            text: "Mobile_UserInfo_NewPassword_Validation_5_Text_0",
          },
        });
      }
    }
  };

  const handleChangeUsrinfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if (name === "usrbirth" && value.length < 11) {
      setUsrinfo({ ...usrinfo, [name]: onlyNumbers(value) });

      return;
    } else {
      if (validatename(value)) {
        return setErrors({
          ...errors,
          usrname: {
            type: "fail",
            text: "특수문제를 사용할 수 없습니다.",
          },
        });
      } else {
        setErrors({
          ...errors,
          usrname: {
            type: "",
            text: "",
          },
        });
      }
    }
    setUsrinfo({ ...usrinfo, [name]: value });
  };

  useEffect(() => {
    setSendEmail(`${changeEmail.id}@${changeEmail.email}`);
    setErrors({ ...errors, email: error.email });
  }, [selectEmail, changeEmail]);

  useEffect(() => {
    if (usrinfo.usrbirth.length === 8) {
      const newInfo = birthFunc(usrinfo.usrbirth);

      setUsrinfo({ ...usrinfo, usrbirth: newInfo });

      if (!checkBirth(usrinfo.usrbirth)) {
        setErrors({
          ...errors,
          usrbirth: {
            type: "fail",
            text: "",
          },
        });
      } else {
        setErrors({
          ...errors,
          usrbirth: {
            type: "",
            text: "",
          },
        });
      }
    } else if (usrinfo.usrbirth.length !== 10) {
      setErrors({
        ...errors,
        usrbirth: {
          type: "",
          text: "",
        },
      });
    }
  }, [usrinfo.usrbirth]);

  return (
    <div className={style.step3}>
      <section className={style.section1}>
        <p className={style.item}>
          {t("Mobile_UserInfo_Phone_0")}
          <b>{passUserInfo.usrphoneno}</b>
        </p>
        <p className={style.line}></p>
        <TextInput
          label={`${t("Mobile_UserInfo_UserName_0")} *`} //"이름 *"
          value={usrinfo.usrname}
          state={errors.usrname.type}
          name="usrname"
          handleChange={handleChangeUsrinfo}
        />
        <TextInput
          label={`${t("Mobile_UserInfo_BOD_0")} *`} //"생년월일 *"
          value={usrinfo.usrbirth}
          state={errors.usrbirth.type}
          name="usrbirth"
          handleChange={handleChangeUsrinfo}
          placeholder="YYYY-MM-DD"
          maxLength={10}
          type="tel"
        />
        <div className={style.selectGender}>
          <div className={`${style.select}`}>
            <p className={style.selectTitle}>{t("Mobile_UserInfo_Sex_0")}</p>
            <Select
              menus={genderDatas}
              select={usrinfo.usrgender}
              onClick={(select: any) => {
                setUsrinfo({ ...usrinfo, usrgender: select });
              }}
            />
          </div>
        </div>
        <p className={style.line}></p>
        {/* </section>
      <section className={style.section2}> */}
        <div className={style.emailArea}>
          <TextInput
            label={`${t("Mobile_UserInfo_Email_0")} *`} //"이메일 *"
            value={changeEmail.id}
            state={errors.email.type}
            name="id"
            handleChange={handleChange}
            placeholder="Mobile_Join_UserInfo_Email_Success_Text_0"
          />
          <div className={style.selectEmail}>
            <span className={style.emailIcon}>@</span>
            <div className={style.emailInput}>
              <TextInput
                value={changeEmail.email}
                name="email"
                handleChange={handleChange}
                placeholder={selectEmail?.value}
              />
            </div>
            <div className={style.select}>
              <Select
                menus={emails}
                select={selectEmail}
                onClick={(select: DefaultKeyValue) => {
                  setSelectEmail(select);
                  setChangeEmail({
                    ...changeEmail,
                    email: select.key === 0 ? "" : select.value,
                  });
                }}
              />
            </div>
          </div>
          <p className={`${style.emailText} ${style[errors.email.type]}`}>
            {errors.email.type === "success" && <SuccessSVG />}
            {errors.email.type === "fail" && <FailSVG />}
            {t(errors.email.text)}
          </p>
          <DefaultBtn
            text="Mobile_UserInfo_DuplicatiateCheck_Button_0"
            onClick={handleDoubleCheckEmail}
            activate={changeEmail.id.length > 0 && changeEmail.email.length > 0}
          />
        </div>
        <div className={style.pwArea}>
          <TextInput
            label={`${t("Mobile_UserInfo_Password_0")} *`} //"비밀번호 *"
            value={pw.pw}
            name="pw"
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPW({ ...pw, pw: event.target.value });
              //setErrors({ ...errors, pw: error });
            }}
            placeholder="Mobile_Join_Password_01"
            type="password"
            onBlur={validatePW}
            state={errors.pw.type}
            msg={errors.pw.text}
            isPassword
          />
          <TextInput
            label={`${t("Mobile_UserInfo_PasswordReconfirm_0")} *`} //"비밀번호 재확인 *"
            value={pw.repw}
            name="repw"
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPW({ ...pw, repw: event.target.value });
              //setErrors({ ...errors, repw: error });
            }}
            placeholder="Mobile_Join_Password_01"
            type="password"
            onBlur={validatePW}
            state={errors.repw.type}
            msg={errors.repw.text}
          />
        </div>
        <div className={style.nicknameArea}>
          <TextInput
            label={`${t("Mobile_UserInfo_Nickname_0")} *`} //"닉네임 *"
            value={nickname}
            name="nickname"
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNickname(event.target.value);
            }}
            placeholder="Mobile_Common_InsertBox_Text_1_0"
            state={errors.nickname.type}
            msg={errors.nickname.text}
          />
          <DefaultBtn
            text="Mobile_UserInfo_DuplicatiateCheck_Button_0"
            onClick={handleDoubleCheckNickname}
            activate={nickname?.toString().length > 0}
          />
        </div>
      </section>
      <JoinNavigator
        onNext={() => {
          joinuserApi.refetch();
        }}
        nextAction={
          usrinfo.usrbirth !== "" &&
          usrinfo.usrname !== "" &&
          sendEmial !== "" &&
          nickname !== "" &&
          pw.pw !== "" &&
          pw.repw !== "" &&
          errors.email.type === "success" &&
          errors.nickname.type === "success" &&
          errors.repw.type === "success"
        }
      />
    </div>
  );
};

export default Step3;
