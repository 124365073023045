export const defaultPassData = {
  authidx: "",
  usrname: "",
  joindate: "",
  usremail: "",
  usrphoneno: "",
  authkey: "",
  countryno: "",
  countrycode: "",
};
