import style from "./Terms.module.scss";

export default function Terms2() {
  return (
    <div className={style.terms}>
      ㈜엑스바디가 제공하는 서비스를 이용하도록 하기 위해서 그 수집 및 이용이
      필요한 최소한의 개인정보이므로 해당 항목에 동의를 해 주셔야 서비스 이용이
      가능합니다.
      <br />
      <br />
      개인정보 수집 이용에 관한 동의는 필수적이지 않으며, 귀하는 이에 대해
      동의를 거부할 권리가 있습니다.
      <br />
      <br />
      다만, 개인정보 제공에 동의하지 않으실 경우 ㈜엑스바디가 제공하는 서비스
      제공이 불가합니다.
      <br />
      <br />
      <h2>■ [필수] 개인정보 수집 및 이용 동의 </h2>
      <br />
      <table>
        <tr>
          <th>수집·이용 목적</th>
          <th>수집·이용하는 개인정보 항목</th>
          <th>보유기간</th>
        </tr>
        <tr>
          <td>피검사자 등록 및 관리 </td>
          <td>
            [필수] 성명, 생년월일, 전화번호, 입사년도, 중복가입확인정보(DI){" "}
            <br />
            [선택] 사번, 이메일, 성별, 사업장, 직군, 부서
          </td>
          <td>
            1. 원칙: 피검사자 파기 요청시까지
            <br />
            2. 다만, 다음의 사유에 해당하는 경우에는 그 해당 기간 종료시까지
            <br />- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래
            등에서의 소비자 보호에 관한 법률){" "}
          </td>
        </tr>
        <tr>
          <td>개인맞춤형 서비스 제공 </td>
          <td>검사회차, 최초검사일, 검사결과 수치, 검사결과 이미지</td>
          <td>
            1. 원칙: 피검사자 탈퇴시까지
            <br />
            2. 다만, 다음의 사유에 해당하는 경우에는 그 해당 기간 종료시까지
            <br />- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래
            등에서의 소비자 보호에 관한 법률){" "}
          </td>
        </tr>
      </table>
    </div>
  );
}
