import { useState, useEffect, useRef } from "react";
import style from "./ChangeEmailModal.module.scss";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { restfetcher } from "utils/queryClient";
import { validateEmail } from "utils/validate";
import { emails } from "datas/common";

import { ReactComponent as SuccessSVG } from "assets/icon/ic_chevron_check.svg";
import { ReactComponent as FailSVG } from "assets/icon/ic_warning_circle_lined.svg";

import ModalLayout from "../ModalLayout";
import TextInput from "components/inputs/textInput/TextInput";
import DefaultBtn from "components/btns/defaultBtn/DefaultBtn";
import Select from "components/selects/Select";
import { setErrorCode } from "utils/error";
import { secondsToHMS } from "utils/common";

interface Props {
  email: string;
  handleClose: () => void;
  onChange: (type: string, value: string) => void;
}

let timer: any = null;

const ChangeEmailModal = ({ email, handleClose, onChange }: Props) => {
  const matchRef = useRef<HTMLDivElement>(null);

  const { t, i18n } = useTranslation();
  const error = {
    type: "none",
    text: "",
  };

  const [selectEmail, setSelectEmail] = useState<DefaultKeyValue>(emails[0]);
  const [changeEmail, setChangeEmail] = useState({
    id: "",
    email: "",
  });
  const [errorState, setErrorState] = useState(error);
  const [authError, setAuthState] = useState(error);
  const [sendEmail, setSendEmail] = useState("");

  const [authData, setAuthData] = useState({
    authidx: "",
    authkey: "",
    expiredin: 0,
  });

  const [count, setCount] = useState(authData.expiredin);

  const duplicatecheckEmailApi = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/duplicatecheck",
        params: {
          type: "EMAIL",
          data: sendEmail,
        },
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        setErrorState({
          type: "success",
          text: "Mobile_UserInfo_Email_Validation_1_Text_0",
        });
        requestSendEmail.mutate();
      } else {
        setErrorState({
          type: "fail",
          text: t(setErrorCode(data?.errcode).toString()),
        });
      }
    },
  });

  const requestSendEmail = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/auth/code/join/send",
        params: {
          email: sendEmail,
          language: i18n.language,
        },
      }),

    onSuccess: (data: any) => {
      if (data?.result === "success") {
        const { authinfo } = data;

        setAuthData({
          ...authData,
          authidx: authinfo.authidx,
          expiredin: authinfo.expiredin,
        });

        if (timer !== 0) {
          setCount(0);
        }
        if (authinfo.expiredin > 0) handleTimer(authinfo.expiredin);
      } else {
        alert(t(setErrorCode(data?.errcode).toString()));
      }
    },
  });

  const checkAuthCodeMatch = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "v1/auth/code/join/valid",
        params: {
          authidx: authData.authidx,
          authkey: authData.authkey,
        },
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        setAuthState({
          text: "Mobile_Join_IdentityVerification_Success_Text_0",
          type: "success",
        });

        clearInterval(timer);
      } else {
        setAuthState({
          text: t(setErrorCode(data?.errcode).toString()),
          type: "fail",
        });
      }
    },
  });

  const updateemailApi = useMutation({
    mutationFn: () =>
      restfetcher({
        method: "POST",
        path: "api/user/updateemail",
        params: {
          usremail: sendEmail,
          authidx: authData.authidx,
        },
      }),
    onSuccess: (data: any) => {
      if (data?.result === "success") {
        const { usremail } = data;

        setErrorState(error);
        onChange("usremail", usremail);
        setSendEmail("");
        setChangeEmail({
          id: "",
          email: "",
        });
        alert(t("Mobile_Common_Save_Pop_0"));
        handleClose();
      } else {
        alert(t(setErrorCode(data?.errcode).toString()));
      }
    },
  });

  const handleClickMatch = () => {
    checkAuthCodeMatch.mutate();
  };

  const handleTimer = (expiredin: number) => {
    if (timer) {
      clearInterval(timer);
    }

    let time = expiredin;
    timer = setInterval(() => {
      if (time > 0) {
        time = time - 1;
      } else {
        clearInterval(timer);
      }
      setCount(time);
    }, 1000);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "email") {
      if (selectEmail?.key === 0) {
        setChangeEmail({ ...changeEmail, email: value });
      } else {
        setChangeEmail({ ...changeEmail, email: selectEmail?.value });
      }
    } else {
      setChangeEmail({ ...changeEmail, id: value });
    }
  };

  const handleSubmit = () => {
    if (authError.type === "success") {
      if (
        typeof window !== "undefined" &&
        window?.confirm(t("Mobile_UserInfo_Common_Edit_Pop_0"))
      ) {
        updateemailApi.mutate();
      }
    }
  };

  const handleChangeAuthkey = (value: string) => {
    setAuthData({ ...authData, authkey: value });
    setAuthState({
      text: "",
      type: "",
    });
  };

  const handleDoubleCheckEmail = () => {
    const validate = validateEmail(sendEmail);
    let errorEmail = error;
    if (!validate) {
      errorEmail = {
        type: "fail",
        text: "Mobile_UserInfo_Email_Validation_2_Text_0",
      };
      return setErrorState(errorEmail);
    } else {
      duplicatecheckEmailApi.mutate();
    }
  };

  useEffect(() => {
    setSendEmail(`${changeEmail.id}@${changeEmail.email}`);
    setErrorState(error);
  }, [selectEmail, changeEmail]);

  return (
    <ModalLayout
      handleClose={handleClose}
      title="Mobile_UserInfo_NewEmail_Pop_Title_0"
      handleSubmit={handleSubmit}
      rightAction={authError.type === "success"}
    >
      <div className={style.changeEmailModal}>
        <div className={style.section}>
          <div className={style.item}>
            <p className={style.itemTitle}>
              {t("Mobile_UserInfo_CurrentEmail_0")}
            </p>
            <p className={style.nowEmail}>{email}</p>
          </div>
          <div>
            <div className={style.item}>
              <p
                className={
                  errorState.type === "fail"
                    ? `${style.itemTitle} ${style.failtext}`
                    : style.itemTitle
                }
              >
                {t("Mobile_UserInfo_NewEmail_0")} *
              </p>
              <TextInput
                value={changeEmail.id}
                name="select"
                handleChange={handleChange}
                placeholder="Mobile_UserInfo_Email_InsertBox_Text_0"
                parentStyle={{
                  backgroundColor: "rgba(0,0,0,0)",
                  height: "36px",
                }}
              />
              <div className={style.selectEmail}>
                <span className={style.emailIcon}>@</span>
                <TextInput
                  value={changeEmail.email}
                  name="email"
                  handleChange={handleChange}
                  placeholder="Mobile_Common_InsertBox_Text_2_0"
                  parentStyle={{
                    backgroundColor: "rgba(0,0,0,0)",
                    height: "36px",
                    width: "137px",
                  }}
                />
                <div className={`selectBackWhite ${style.select}`}>
                  <Select
                    menus={emails}
                    select={selectEmail}
                    onClick={(select: DefaultKeyValue) => {
                      setSelectEmail(select);
                      setChangeEmail({
                        ...changeEmail,
                        email: select.key === 0 ? "" : select.value,
                      });
                    }}
                  />
                </div>
              </div>
              <p className={`${style.emailText} ${style[errorState.type]}`}>
                {errorState.type === "success" && <SuccessSVG />}
                {errorState.type === "fail" && <FailSVG />}
                {t(errorState.text)}
              </p>
            </div>
            <p className={style.checkBtn}>
              <DefaultBtn
                text="Mobile_Join_PhoneVerification_SendNumber_0"
                onClick={handleDoubleCheckEmail}
              />
            </p>
          </div>
        </div>
        {count > 0 && (
          <div className={`${style.section} ${style.match}`} ref={matchRef}>
            <p className={`${style.title} ${style.countTitle}`}>
              {t("Mobile_Join_PhoneVerification_EnterNumber_0")}
              <span className={style.count}>{secondsToHMS(count)}</span>
            </p>
            <TextInput
              value={authData.authkey}
              name="authkey"
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeAuthkey(event?.target?.value);
              }}
              state={authError.type}
              msg={authError.text}
            />
            <DefaultBtn
              text="Mobile_Join_Verification_0"
              onClick={handleClickMatch}
              activate={authData.authkey.length > 0}
            />
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

export default ChangeEmailModal;
