import style from "./Terms.module.scss";

const Terms2Jp = () => {
  return (
    <div className={style.terms}>
      (株)エクスボディが提供するサービスを利用するためには、その収集及び利用が必要な最小限の個人情報ですので、該当項目に同意していただくとサービス利用が可能です。
      <br />
      個人情報の収集および利用に関する同意は必須ではなく、同意を拒否する権利があります。
      ただし、個人情報の提供にご同意いただけない場合、exbody Co.,
      Ltd.が提供するサービスを利用することはできません。
      <br />
      <br />
      <h2>■ [必須] 個人情報の収集および利用の同意</h2>
      <br />
      <table>
        <tr>
          <th>収集・利用目的</th>
          <th>収集・利用する個人情報項目</th>
          <th>保持期間</th>
        </tr>
        <tr>
          <td>被験者の登録と管理</td>
          <td>
            【必須】氏名、生年月日、電話番号、入社年、重複加入確認情報（DI）
            <br />
            <br />
            【任意】社員番号、メールアドレス、性別、事業所、職種、部署
          </td>
          <td>
            1. 原則：被検者破棄要請時まで
            <br />
            2. ただし、次の事由に該当する場合には、その該当期間終了時まで
            <br />- 消費者の苦情または紛争処理に関する記録 : 3年
            (電子商取引などでの消費者保護に関する法律)
          </td>
        </tr>
        <tr>
          <td>パーソナライズされたサービスを提供</td>
          <td>検査会次、最初の検査日、検査結果数値、検査結果画像</td>
          <td>
            1. 原則: 被検者脱退時まで
            <br />
            2. ただし、次の事由に該当する場合には、その該当期間終了時まで
            <br />- 消費者の苦情または紛争処理に関する記録 :
            3年(電子商取引などでの消費者保護に関する法律)
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Terms2Jp;
