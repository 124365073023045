import style from "./Terms.module.scss";

export default function Terms3En() {
  return (
    <div className={style.terms}>
      exbody Co., Ltd. (hereinafter referred to as the "Company") complies with
      the Personal Information Protection Act and other relevant laws and
      regulations. The Company is committed to safeguarding personal and
      sensitive information by establishing and implementing this Privacy Policy
      in accordance with applicable legal standards.
      <br />
      <br />
      The Privacy Policy is made available on the initial screen of the service
      for users to access at any time. It may be updated in accordance with
      changes to relevant laws, guidelines, notifications, or the Company’s
      service policies. Please read this policy carefully and proceed with
      examinations only if you agree to the terms.
      <br />
      <br />
      1. Categories and Purposes of Processed Personal Information
      <br />
      <br />
      The Company collects and processes personal and sensitive information to
      operate its business, provide and improve products and services,
      communicate with users, and develop effective advertising.
      <br />
      <br />
      The personal information listed below is necessary to provide services to
      all users and is collected based on the consent of the applicant or as
      permitted by law.
      <br />
      <br />
      Examinees have the right to refuse consent to this agreement; however,
      refusal to consent to the collection and use of mandatory information may
      result in limitations on the use of services.
      <br />
      <br />
      <table>
        <tr>
          <th>Collection Timing</th>
          <th>Collected Items</th>
          <th>Purpose of Collection</th>
        </tr>
        <tr>
          <td>At Registration</td>
          <td>
            [Required] Name, date of birth, phone number, year of employment,
            duplicate subscription verification information (DI) <br />
            <br />
            [Additional Required Information for Minors]
            <br /> Name of legal representative, email address, phone number,
            duplicate subscription verification information (DI)
          </td>
          <td>
            Verify intent to subscribe, service
            registration/modification/cancellation,
            <br /> determine service eligibility (e.g., age verification) and
            prevent duplicate subscriptions, deliver notifications,,
            <br /> manage user participation spaces, conduct user surveys,
            provide personalized services,,
            <br /> offer promotional information and partnership services,
            confirm shipping address and contact information for product
            delivery.,
            <br /> product delivery address and contact information,Provide
            personalized services.
          </td>
        </tr>
        <tr>
          <td>At Time of Examination</td>
          <td>
            [Required] Examination session, date of first examination,
            examination result data, examination result images
          </td>
          <td>Provide personalized services.</td>
        </tr>
      </table>
      <br />
      <h3>2. Retention and Use Period of Personal Information</h3>
      <br />
      1. The Company retains personal information from the date of consent until
      the examinee requests its deletion. If the examinee requests deletion,
      their personal information will be destroyed or stored separately without
      delay in accordance with Article 39-6 of the Personal Information
      Protection Act. However, information retained under relevant laws is
      exempt from this policy.
      <br />
      <br />
      Retention and Usage Period According to Relevant Laws:
      <br />
      1) Important commercial documents and slips: 10 years (important
      documents) / 5 years (slips) (Commercial Act)
      <br />
      2) Books and supporting documents related to transactions: 5 years
      (Framework Act on National Taxes, Corporate Tax Act, VAT Act)
      <br />
      3) Log data and IP addresses for communication verification: 3 months
      (Protection of Communications Secrets Act)
      <br />
      4) Records of labeling/advertising: 6 months (Act on Consumer Protection
      in Electronic Commerce)
      <br />
      5) Records on contracts or withdrawal of offers: 5 years (Act on Consumer
      Protection in Electronic Commerce)
      <br />
      6) Records on payment and supply of goods/services: 5 years (Act on
      Consumer Protection in Electronic Commerce)
      <br />
      7) Records on consumer complaints/dispute resolution: 3 years (Act on
      Consumer Protection in Electronic Commerce)
      <br />
      8) Records on collection/processing/use of credit information: 3 years
      (Act on the Use and Protection of Credit Information)
      <br />
      9) Records on identity verification: 6 months (Act on Promotion of
      Information and Communications Network Utilization and Information
      Protection)
      <br />
      <br />
      <h3>3. Delegation of Personal Information Processing</h3>
      <br />
      The Company may delegate the processing of personal information to
      external companies to improve its services. In such cases, the Company
      ensures safe management and supervises the use of personal information so
      that it is not used for other purposes.
      <br />
      1) If personal information is delegated, the examinees will be notified in
      advance. <br />
      2) The delegation contract will explicitly specify obligations such as
      compliance with privacy protection guidelines, confidentiality of personal
      information, prohibition of third-party sharing, and liability for
      accidents. The contract will be stored in written or electronic form.
      <br />
      <br />
      <h3>4. Procedures and Methods for Destroying Personal Information</h3>
      When the retention period expires or the purpose of collection and use is
      achieved, the Company destroys personal information without delay.
      <br />
      Destruction Procedures and Methods:
      <br />
      1) Destruction Procedure
      <br />
      A. Personal information subject to destruction is deleted with the
      approval of the Data Protection Officer.
      <br />
      B. Information provided for registration or service application is moved
      to a separate database (or stored in a locked filing cabinet for physical
      documents) and retained for a specific period as stipulated by internal
      policies and relevant laws, after which it is destroyed.
      <br />
      c. The information is not used for any purpose other than those required
      by law.
      <br />
      2) Methods
      <br />
      a. Electronic files are destroyed using technical methods that make
      recovery impossible.
      <br />
      b. Physical documents are shredded using a shredder.
      <br />
      <br />
      <h3>5. Measures to Ensure the Security of Personal Information</h3>
      The Company takes the following measures to ensure the secure processing
      of personal information:
      <br />
      <br />
      1) Encryption of Personal Information: Personal information is protected
      by passwords, and critical data is encrypted or secured using file locking
      and other security features.
      <br />
      2) Technical Measures Against Hacking: The Company installs and regularly
      updates security programs to prevent personal information leaks or damage
      due to hacking or viruses.
      <br />
      3) Access Restriction to Personal Information Systems: Access to personal
      information is restricted to a limited number of authorized employees, and
      passwords are regularly updated.
      <br />
      4) Training for Employees Handling Personal Information: Regular training
      is provided to employees on new security technologies and obligations
      regarding personal information protection.
      <br />
      5) Physical Access Control: The physical storage locations of personal
      information are secured with access control procedures.
      <br />
      <br />
      <h3>6. Personal Information Protection Officer</h3>
      Examinees can contact the Personal Information Protection Officer or the
      relevant department regarding any inquiries or complaints related to
      personal information while using the Company's services. The Company will
      provide prompt and sufficient responses to all inquiries.
      <br />
      <br />
      ㆍPersonal Information Protection Officer: Mi Sook Kim <br />
      ㆍDepartment for Personal Information Management and Access: Corporate
      Research Institute, Server Team <br />
      ㆍDepartment for Personal Information Complaints: Planning and
      Coordination Office <br />
      ㆍComplaint Phone Number: +82-2-6925-6080
      <br />
      ㆍComplaint Email: secure@exbody.com
      <br />
      <br />
      <h3>7. Reporting and Consultation on Personal Information Breaches</h3>
      If you need to report or consult regarding a personal information breach,
      you can contact the following organizations:
      <br />
      <br />
      <table>
        <tr>
          <th>Organizations</th>
          <th>Homepage</th>
          <th>Phone number</th>
        </tr>
        <tr>
          <td>Privacy Reporting Center</td>
          <td>http://privacy.kisa.or.kr/kor/main.jsp</td>
          <td>(without area code) 118</td>
        </tr>
        <tr>
          <td>Privacy Shield Board</td>
          <td>https://www.kopico.go.kr/ </td>
          <td>1833-6972</td>
        </tr>
        <tr>
          <td>Cyber Investigations Division, Office of the Attorney General</td>
          <td>http://www.spo.go.kr/spo/index.jsp</td>
          <td>1301 (without area code)</td>
        </tr>
        <tr>
          <td>National Police Agency Cyber Safety Bureau</td>
          <td>http://cyberbureau.police.go.kr/index.d</td>
          <td>(without area code) 182</td>
        </tr>
      </table>
      <br />
      <br />
      <h3>8. Obligations for Processing Personal Information</h3>
      <br />
      (1) This Privacy Policy may be revised due to changes in laws, government
      policies, or the Company's internal policies. In the event of any
      additions, deletions, or modifications, the changes will be announced on
      the exbody service website at least 7 days before they take effect.
      <br />
      <br />
      (2) This Privacy Policy will take effect starting November 15, 2024.
      <br />
      <br />
      ㆍAnnouncement Date: November 15, 2024
      <br />
      ㆍEffective Date: December 17, 2024
    </div>
  );
}
